import React from 'react';

type Props = {
  children: React.ReactNode;
};

export default function SelectItemHeader({ children }: Props) {
  return (
    <div className='min-w-full px-4 py-2 text-lg font-bold bg-gray-100 border-t border-b border-gray-300'>
      {children}
    </div>
  );
}
