import React, { useContext } from 'react';
import Layout from 'components/layout';
import AuthContext from 'context/auth-context';
import { navigate } from 'gatsby';
import SEO from 'components/seo';

type Props = {
  children: React.ReactNode;
};

const HomeLayout = ({ children }: Props) => {
  const { user } = useContext(AuthContext);

  if (typeof window !== 'undefined') {
    if (!user) {
      navigate('/login');
      return null;
    }
  }

  return user ? (
    <Layout>
      <SEO title='Opening Letter' />
      <div className='container max-w-md p-4 m-auto opening-letter'>
        {children}
      </div>
    </Layout>
  ) : null;
};

export default HomeLayout;
