import React, { useContext, useLayoutEffect } from 'react';
import { FileText, Video } from 'react-feather';
import ToolboxContext from 'context/toolbox-context';
import { MediaType, ToolboxData } from 'hooks/use-toolbox-filter';
import ToolboxModal from 'components/tool-modal';
import Portal from 'components/portal';
import marked from 'marked';
import shave from 'shave';

type ToolboxNode = {
  node: {
    data: ToolboxData;
  };
};

type Props = {
  data: ToolboxNode[];
};

export default function ToolboxGrid({ data = [] }: Props) {
  const { filterState, selectedToolState, setSelectedToolState } = useContext(
    ToolboxContext
  );

  const { categoryFilters, mediaFilters } = filterState;

  // @ts-ignore
  useLayoutEffect(() => shave('.intro', 70));

  const getMediaTypeIcon = (mediaType: MediaType) => {
    switch (mediaType) {
      case 'Text':
        return <FileText color='#2563eb' />;
      case 'Video':
        return <Video color='#2563eb' />;
    }
  };

  const handleSetSelectedTool = (selectedTool: { data: ToolboxData }) => {
    const { data } = selectedTool;
    setSelectedToolState(data);
  };

  const renderTools = (toolboxData: ToolboxNode[] = []) =>
    toolboxData
      .filter(({ node }) => {
        const {
          data: { Media_Type, Tags },
        } = node;
        if (categoryFilters.size > 0 || mediaFilters.size > 0) {
          return (
            [...categoryFilters].some((filter) =>
              Tags?.map((t) => t.toLowerCase()).includes(filter as string)
            ) || mediaFilters.has(Media_Type.toLowerCase() as MediaType)
          );
        }

        return true;
      })
      .map(({ node }, index: number) => {
        const {
          data: {
            Name,
            Description,
            Tags,
            Cover_Image: [firstImage],
            Media_Type,
            Hide_Intro: hideIntro,
          },
        } = node;
        return (
          <div
            className='relative flex flex-col overflow-hidden transition-shadow duration-150 ease-in transform bg-white rounded shadow-lg cursor-pointer hover:shadow-2xl animation-scale-in max-h-96'
            key={`${Name}-${index}`}
            onClick={() => handleSetSelectedTool(node)}
          >
            <div
              className='relative p-20 antialiased bg-gray-300 rounded-t'
              style={{
                backgroundImage: `url('${firstImage?.thumbnails.large.url}')`,
                backgroundSize: 'cover',
                backgroundBlendMode: 'multiply',
              }}
            >
              <div className='absolute top-0 left-0 flex items-center justify-center w-10 h-10 m-2 bg-white rounded-full'>
                {getMediaTypeIcon(Media_Type)}
              </div>
            </div>
            <div className='p-3 space-y-4'>
              <span className='block mb-1 text-lg font-bold'>{Name}</span>
              {Tags?.length &&
                Tags.map((tag) => (
                  <span className='px-2 py-1 mr-2 text-xs font-bold text-white uppercase bg-blue-600 rounded-full'>
                    {tag}
                  </span>
                ))}
              {!hideIntro && (
                <div
                  className='text-sm intro'
                  dangerouslySetInnerHTML={{ __html: marked(Description) }}
                />
              )}
            </div>
          </div>
        );
      });

  return (
    <div className='relative'>
      <div className='grid grid-flow-row gap-6 md:grid-cols-3'>
        {renderTools(data)}
      </div>
      {selectedToolState && (
        <Portal>
          <ToolboxModal />
        </Portal>
      )}
    </div>
  );
}
