import PostsLayout from 'components/posts-layout';
import PostFooter from 'components/post-footer';
import ReactPlayer from 'react-player';
import Image from 'components/image';
import SEO from 'components/seo';
import Links from 'components/links';
import { LinkLabel } from 'utils/constants';
import { postMap, slugify } from 'utils/post-map';
import * as React from 'react';
export default {
  PostsLayout,
  PostFooter,
  ReactPlayer,
  Image,
  SEO,
  Links,
  LinkLabel,
  postMap,
  slugify,
  React
};