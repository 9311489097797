import React from 'react';

type Link = {
  title: string;
  links: {
    label: string;
    url: URLString;
  }[];
};

type Props = {
  links: Link[];
};

export default function Links({ links }: Props) {
  return (
    <div className='px-8 py-4 mt-16 mb-6 space-y-4 bg-blue-100 rounded'>
      <div className='text-2xl font-bold'>🔗 Links mentioned</div>
      {links.map(({ title, links: l }) => (
        <div className='container'>
          <h4 className='text-xl'>{title}</h4>
          <ul className='pl-12 list-disc'>
            {l.map(({ url, label }) => (
              <li className='font-bold text-blue-600'>
                <a href={url} target='_blank'>
                  {label}
                </a>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
}
