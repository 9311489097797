import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { MDXProvider } from '@mdx-js/react';

import Header from './header';

type Props = {
  children: React.ReactNode;
};
const Layout = ({ children }: Props) => {
  const components = {
    h1: (props: React.HTMLAttributes<HTMLHeadingElement>) => (
      <h1
        className='mb-8 text-5xl font-bold text-gray-700 font-display'
        {...props}
      />
    ),
    h2: (props: React.HTMLAttributes<HTMLHeadingElement>) => (
      <h1 className='mb-3 text-xl font-bold text-gray-700' {...props} />
    ),
    p: (props: React.HTMLAttributes<HTMLParagraphElement>) => (
      <p className='mb-6 text-lg text-gray-800 md:text-base' {...props} />
    ),
    a: (props: React.HTMLAttributes<HTMLAnchorElement>) => (
      <a
        className='text-orange-600'
        target='_blank'
        rel='noopener noreferrer'
        {...props}
      />
    ),
    hr: (props: React.HTMLAttributes<HTMLHRElement>) => (
      <hr className='my-12' {...props} />
    ),
    ul: (props: React.HTMLAttributes<HTMLUListElement>) => (
      <ul className='pl-12 mb-6 list-disc' {...props} />
    ),
    ol: (props: React.HTMLAttributes<HTMLOListElement>) => (
      <ol className='pl-12 mb-6 list-decimal' {...props} />
    ),
    li: (props: React.HTMLAttributes<HTMLLIElement>) => (
      <li className='mb-2' {...props} />
    ),
    img: (props: React.HTMLAttributes<HTMLImageElement>) => (
      <img className='w-full' {...props} />
    ),
  };
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={(data) => (
        <MDXProvider components={components}>
          <div className='min-h-full'>
            <Header siteTitle={data.site.siteMetadata.title} />
            <div className='pt-32 md:pt-16'>{children}</div>
          </div>
        </MDXProvider>
      )}
    />
  );
};

export default Layout;
