import React, { useContext } from 'react';
import { Link } from '@reach/router';
import { Mail, Zap } from 'react-feather';
import AuthContext from 'context/auth-context';
import AAPLogo from 'images/aap-logo.svg';

type Props = {
  siteTitle: string;
};

export default function Header({ siteTitle }: Props) {
  const { magic, setUser } = useContext(AuthContext);

  const isPartiallyActive = ({
    isPartiallyCurrent,
  }: {
    isPartiallyCurrent: boolean;
  }) => {
    return isPartiallyCurrent
      ? {
          className:
            'items-center flex h-full border-blue-500 font-bold border-b-4',
        }
      : { className: 'items-center flex h-full' };
  };

  const handleLogout = async () => {
    await fetch('/.netlify/functions/logout', {
      method: 'GET',
    });

    setUser(null);
    await magic.user.logout();
  };

  return (
    <nav>
      <div className='fixed z-50 flex flex-col items-center justify-center w-screen h-32 p-2 pb-0 space-y-1 text-lg text-center bg-orange-500 md:space-y-0 md:p-0 md:grid-cols-3 md:grid md:text-base md:h-16'>
        <ul className='flex items-center order-3 h-full mt-4 text-white md:mt-0 md:order-first'>
          <li className='h-full transition duration-100 hover:bg-orange-400'>
            <Link to='/big-ideas/' getProps={isPartiallyActive}>
              <div className='flex items-center px-4'>
                <Zap color='#fff' size={20} />
                <span className='ml-1'>Big Ideas</span>
              </div>
            </Link>
          </li>
          <li className='h-full transition duration-100 hover:bg-orange-400'>
            <Link to='/app/posts' getProps={isPartiallyActive}>
              <div className='flex items-center px-4'>
                <Mail color='#fff' size={20} />
                <span className='ml-1'>Emails</span>
              </div>
            </Link>
          </li>
        </ul>
        <h1 className='text-4xl font-bold text-white md:flex md:justify-center justify-self-center font-display'>
          <Link to='/'>
            <AAPLogo className='w-1/3 h-auto m-auto md:h-9 md:w-auto' />
          </Link>
        </h1>
        <div className='order-first w-full px-2 mr-8 text-sm text-right text-white transition duration-100 md:order-last md:p-4 place-self-center over:opacity-75 md:text-base'>
          <button onClick={handleLogout}>Logout</button>
        </div>
      </div>
    </nav>
  );
}
