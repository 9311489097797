import { createContext } from 'react';
import {
  ToolboxData,
  ToolboxFilterActions,
  ToolboxFilterState,
} from 'hooks/use-toolbox-filter';

type ContextState = {
  filterState: ToolboxFilterState;
  dispatchFilter: React.Dispatch<ToolboxFilterActions>;
  selectedToolState: ToolboxData | null;
  setSelectedToolState: React.Dispatch<ToolboxData | null>;
};

const ToolboxContext = createContext({} as ContextState);

export default ToolboxContext;
