import React from 'react';
import { Link } from '@reach/router';

export default function StartHereLinks() {
  return (
    <div className='grid gap-4 md:grid-cols-2 md:grid-rows-2'>
      <Link
        to='/app/posts/project-kickoff'
        className='inline-block p-3 font-bold text-center transition-colors duration-100 bg-green-200 rounded cursor-pointer hover:bg-blue-100'
      >
        💰 I'm raising a fund!
      </Link>
      <Link
        to='/app/posts/what-do-vacuums-gummies-chalkboards-all-have-in-common'
        className='inline-block p-3 font-bold text-center transition-colors duration-100 bg-orange-200 rounded cursor-pointer hover:bg-blue-100'
      >
        🛒 I'm building an ecomm store!
      </Link>
      <Link
        to='/app/posts/using-big-mario-to-grow-the-aap-audience'
        className='inline-block p-3 font-bold text-center transition-colors duration-100 bg-red-200 rounded cursor-pointer hover:bg-blue-100'
      >
        📣 I'm building an audience!
      </Link>
      <Link
        to='/app/posts/bankshots-only-work-in-basketball'
        className='inline-block p-3 font-bold text-center transition-colors duration-100 bg-indigo-200 rounded cursor-pointer hover:bg-blue-100'
      >
        💡 I wanna become enlightened!
      </Link>
    </div>
  );
}
