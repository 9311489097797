import React from 'react';
import { Link } from '@reach/router';

type Props = {
  children: React.ReactNode;
  slug: string;
};

const isPartiallyActive = ({
  isPartiallyCurrent,
}: {
  isPartiallyCurrent: boolean;
}) => {
  return isPartiallyCurrent
    ? {
        className:
          'block py-4 px-4 bg-blue-200 text-blue-900 border-l-4 border-blue-500',
      }
    : {
        className:
          'block border-0 py-4 px-4 transition duration-200 ease-in-out hover:bg-blue-100 hover:text-blue-900',
      };
};

export default function SidebarItem({ children, slug }: Props) {
  return (
    <Link to={slug} getProps={isPartiallyActive}>
      {children}
    </Link>
  );
}
