export const postMap = {
         start_here: {
           group: 'tktk',
           slug: 'start-here',
           label: '📌 Start Here',
         },
         project_kickoff: {
           group: 'fund',
           slug: 'project-kickoff',
           label: 'Project Kickoff',
         },
         making_the_sales_map: {
           group: 'fund',
           slug: 'making-the-sales-map',
           label: 'Making The Sales Map',
         },
         nailing_our_pitch: {
           group: 'fund',
           slug: 'nailing-our-pitch',
           label: 'Nailing Our Pitch',
         },
         the_taste_of_rejection: {
           group: 'fund',
           slug: 'the-taste-of-rejection',
           label: 'The Taste of Rejection',
         },
         going_down_the_funnel: {
           group: 'fund',
           slug: 'going-down-the-funnel',
           label: 'Going Down The Funnel',
         },
         how_1_tweet_turned_into_1_5_m: {
           group: 'fund',
           slug: 'how-1-tweet-turned-into-1-5-m',
           label: 'How 1 Tweet Turned Into $1.5M',
         },
         sometimes_you_need_to_be_a_worker_ant: {
           group: 'fund',
           slug: 'sometimes-you-need-to-be-a-worker-ant',
           label: 'Sometimes You Need To Be A Worker Ant',
         },
         what_do_vacuums_gummies_chalkboards_all_have_in_common: {
           group: 'ecom',
           slug: 'what-do-vacuums-gummies-chalkboards-all-have-in-common',
           label: 'What do vacuums, gummies, & chalkboards all have in common?',
         },
         amas_audio_experiments_and_the_sunday_mailbag: {
           group: 'random',
           slug: 'amas-audio-experiments-and-the-sunday-mailbag',
           label: 'AMAs, Audio Experiments, and The Sunday Mailbag!',
         },
         impulse_vs_repeat_vs_splurge_products: {
           group: 'ecom',
           label: 'Impulse vs. Repeat vs. Splurge Products',
           slug: 'impulse-vs-repeat-vs-splurge-products',
         },
         the_power_box: {
           group: 'tktk',
           slug: 'the-power-box',
           label: 'The Power Box',
         },
         validating_demand_for_product_ideas: {
           group: 'tktk',
           slug: 'validating-demand-for-product-ideas',
           label: 'Validating Demand For Product Ideas',
         },
         tools_tips_tricks_to_finding_a_manufacturer: {
           group: 'tktk',
           slug: 'tools-tips-tricks-to-finding-a-manufacturer',
           label: 'Tools, Tips, & Tricks To Finding A Manufacturer',
         },
         every_day_is_my_friday: {
           group: 'tktk',
           slug: 'every-day-is-my-friday',
           label: 'Every Day Is My Friday',
         },
         product_samples_are_on_the_way: {
           group: 'tktk',
           slug: 'product-samples-are-on-the-way',
           label: 'Product Samples Are On The Way!',
         },
         good_artists_copy_great_artists_steal: {
           group: 'tktk',
           slug: 'good-artists-copy-great-artists-steal',
           label: 'Good Artists Copy, Great Artists Steal',
         },
         hiring_a_good_operator_feels_like_magic: {
           group: 'tktk',
           slug: 'hiring-a-good-operator-feels-like-magic',
           label: 'Hiring A Good Operator Feels Like Magic',
         },
         bankshots_only_work_in_basketball: {
           group: 'tktk',
           slug: 'bankshots-only-work-in-basketball',
           label: 'Bankshots Only Work In Basketball',
         },
         back_again_with_the_sunday_mailbag: {
           group: 'tktk',
           slug: 'back-again-with-the-sunday-mailbag',
           label: 'Back Again With The Sunday Mailbag',
         },
         using_big_mario_to_grow_the_aap_audience: {
           group: 'tktk',
           slug: 'using-big-mario-to-grow-the-aap-audience',
           label: 'Using Big Mario To Grow The AAP Audience',
         },
         spoiler_alert_targeting_tai_lopez_s_audience_is_working: {
           group: 'tktk',
           slug: 'spoiler-alert-targeting-tai-lopez-s-audience-is-working',
           label: "Spoiler Alert: Targeting Tai Lopez's Audience Is Working",
         },
         prototyping_the_third_door: {
           group: 'tktk',
           slug: 'prototyping-the-third-door',
           label: 'Prototyping The Third Door',
         },
         its_all_about_taking_the_right_risks: {
           group: 'tktk',
           slug: 'its-all-about-taking-the-right-risks',
           label: "It's All About Taking The Right Risks",
         },
         the_internet_doesnt_care_about_geography: {
           group: 'tktk',
           slug: 'the-internet-doesnt-care-about-geography',
           label: "The Internet Doesn't Care About Geography",
         },
         recap: {
           group: 'tktk',
           slug: 'recap',
           label: 'Recap',
         },
         picking_the_mission_for_the_week: {
           group: 'tktk',
           slug: 'picking-the-mission-for-the-week',
           label: 'Picking The Mission For The Week',
         },
         hitting_a_plateau_should_be_like_greeting_an_old_friend: {
           group: 'tktk',
           slug: 'hitting-a-plateau-should-be-like-greeting-an-old-friend',
           label: 'Hitting A Plateau Should Be Like Greeting An Old Friend',
         },
         were_not_selling_saddles_here: {
           group: 'tktk',
           slug: 'were-not-selling-saddles-here',
           label: "We're Not Selling Saddles Here",
         },
         fridays_ama_25_day_recap_on_the_way: {
           group: 'tktk',
           slug: 'fridays-ama-25-day-recap-on-the-way',
           label: "Friday's AMA + 25 Day Recap On The Way",
         },
         just_the_best_stuff_so_far: {
           group: 'tktk',
           slug: 'just-the-best-stuff-so-far',
           label: 'Just The Best Stuff So Far',
         },
         debating_isn_t_just_for_presidents: {
           group: 'tktk',
           slug: 'debating-isn-t-just-for-presidents',
           label: "Debating Isn't Just For Presidents",
         },
         behind_the_scenes_of_the_first_fund_lp_update: {
           group: 'tktk',
           slug: 'behind-the-scenes-of-the-first-fund-lp-update',
           label: 'Behind The Scenes Of The First Fund LP Update',
         },
         its_monday_and_i_wanna_help_you_nail_your_story: {
           group: 'tktk',
           slug: 'its-monday-and-i-wanna-help-you-nail-your-story',
           label: "It's Monday And I Wanna Help You Nail Your Story",
         },
         today_was_a_total_curveball: {
           group: 'tktk',
           slug: 'today-was-a-total-curveball',
           label: 'Today Was A Total Curveball',
         },
         twenty_four_hours_of_learning_about_collectibles: {
           group: 'tktk',
           slug: '24-hours-of-learning-about-collectibles',
           label: '24 Hours Of Learning About Collectibles',
         },
         building_the_flywheel: {
           group: 'tktk',
           slug: 'building-the-flywheel',
           label: 'Building The Flywheel',
         },
         painting_a_picture_while_tickling_the_brain: {
           group: 'tktk',
           slug: 'painting-a-picture-while-tickling-the-brain',
           label: 'Painting A Picture While Tickling The Brain 🧠',
         },
         growth_is_math_art_science: {
           group: 'tktk',
           slug: 'growth-is-math-art-science',
           label: 'Growth Is Math → Art → Science',
         },
         the_art_of_non_violent_communication: {
           group: 'tktk',
           slug: 'the-art-of-non-violent-communication',
           label: 'The Art Of Non Violent Communication',
         },
         firing_lots_of_growth_bullets: {
           group: 'tktk',
           slug: 'firing-lots-of-growth-bullets',
           label: 'Firing Lots Of Growth Bullets',
         },
         finding_the_bug_in_the_system: {
           group: 'tktk',
           slug: 'finding-the-bug-in-the-system',
           label: 'Finding The Bug In The System',
         },
         the_more_i_know_about_facebook_ads_the_better: {
           group: 'tktk',
           slug: 'the-more-i-know-about-facebook-ads-the-better',
           label: 'The More I Know About Facebook Ads, The Better',
         },
         i_hate_flying_blind_so_i_made_a_roas_calculator: {
           group: 'tktk',
           slug: 'i-hate-flying-blind-so-i-made-a-roas-calculator',
           label: 'I Hate Flying Blind So I Made A ROAS Calculator',
         },
         todays_ama_recording: {
           group: 'tktk',
           slug: 'todays-ama-recording',
           label: "Today's AMA Recording",
         },
         how_id_make_100k_in_a_week_right_now: {
           group: 'tktk',
           slug: 'how-id-make-100k-in-a-week-right-now',
           label: "How I'd Make 100k In A Week Right Now",
         },
         we_got_our_sample: {
           group: 'tktk',
           slug: 'we-got-our-sample',
           label: 'We Got Our Sample!',
         },
         im_obsessed_with_this_business_model: {
           group: 'tktk',
           slug: 'im-obsessed-with-this-business-model',
           label: "I'm Obsessed With This Business Model",
         },
         the_sunday_mailbag_a_special_guest_coming_this_week: {
           group: 'tktk',
           slug: 'the-sunday-mailbag-a-special-guest-coming-this-week',
           label: 'The Sunday Mailbag + A Special Guest Coming This Week!',
         },
         tofu_isnt_just_something_i_like_to_eat: {
           group: 'tktk',
           slug: 'tofu-isnt-just-something-i-like-to-eat',
           label: "Tofu Isn't Just Something I Like To Eat",
         },
         ripping_the_bandaid_off: {
           group: 'tktk',
           slug: 'ripping-the-bandaid-off',
           label: 'Ripping The Bandaid Off',
         },
         my_life_vision: {
           group: 'tktk',
           slug: 'my-life-vision',
           label: 'My Life Vision',
         },
         three_ecom_tactics_that_drove_30k_in_incremental_revenue: {
           group: 'tktk',
           slug: '3-ecom-tactics-that-drove-30k-in-incremental-revenue',
           label: '3 Ecom Tactics That Drove 30k In Incremental Revenue',
         },
         my_5_lessons_learned_from_jack_butcher: {
           group: 'tktk',
           slug: 'my-5-lessons-learned-from-jack-butcher',
           label: 'My 5 Lessons Learned From Jack Butcher',
         },
       };

export const postCategoryLabels = {
  fund: 'Raising a $1M+ Fund',
  ecom: 'Building a $1M+ Ecom Store',
  random: 'Random',
  life: 'Life Wisdom',
};

export function slugify(slug: string = '') {
  const POSTS_PATH = '/app/posts';
  return `${POSTS_PATH}/${slug}`;
}
