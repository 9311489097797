import React from 'react';
import useAuth from './src/hooks/use-auth';
import AuthContext from './src/context/auth-context';
import './src/components/tailwind.css';

const App = ({ element }) => {
  const value = useAuth();

  return <AuthContext.Provider value={value}>{element}</AuthContext.Provider>;
};

export const wrapRootElement = ({ element }) => {
  return <App element={element} />;
};
