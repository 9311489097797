import PostsLayout from 'components/posts-layout';
import PostFooter from 'components/post-footer';
import ReactPlayer from 'react-player';
import Image from 'components/image';
import SEO from 'components/seo';
import * as React from 'react';
export default {
  PostsLayout,
  PostFooter,
  ReactPlayer,
  Image,
  SEO,
  React
};