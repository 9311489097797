import React, { useContext } from 'react';
import marked from 'marked';
import ToolboxContext from 'context/toolbox-context';

export default function ToolModal() {
  const { selectedToolState, setSelectedToolState } = useContext(
    ToolboxContext
  );
  const {
    Name,
    Description,
    Links,
    Tags,
    Emoji,
    Cover_Image: [firstImage],
    Media_Type,
  } = selectedToolState;

  const handleClose = () => {
    setSelectedToolState(null);
  };
  return (
    <div className='fixed top-0 left-0 z-30 flex items-center justify-center w-full h-full px-2 pt-32 overflow-auto text-gray-800 md:p-0 animation-fade-in'>
      <div
        className='absolute w-full h-full bg-gray-900 opacity-50'
        onClick={handleClose}
      />
      <div className='relative z-10 w-full max-h-full mx-auto mt-4 overflow-x-hidden bg-white rounded shadow-lg md:mt-20 md:h-5/6 md:w-11/12 break-word md:max-w-2xl'>
        <div
          className='absolute top-0 right-0 z-50 m-4 cursor-pointer'
          onClick={handleClose}
        >
          <svg
            className='text-black fill-current'
            xmlns='http://www.w3.org/2000/svg'
            width='18'
            height='18'
            viewBox='0 0 18 18'
          >
            <path d='M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z'></path>
          </svg>
        </div>
        <div
          className='relative bg-gray-300 rounded-t shadow-xl p-28'
          style={{
            backgroundImage: `url('${firstImage?.thumbnails.large.url}')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundBlendMode: 'multiply',
          }}
        >
          <div className='absolute bottom-0 left-0 text-6xl transform translate-x-6 translate-y-7'>
            {Emoji}
          </div>
        </div>
        <div className='px-6 py-4 pb-12 antialiased text-left'>
          <div className='flex items-center justify-between pb-3'>
            <h2 className='mt-10 mb-6 text-4xl font-bold text-gray-700 font-display'>
              {Name}
            </h2>
          </div>
          <div
            className='space-y-6 leading-relaxed modal-content'
            dangerouslySetInnerHTML={{ __html: marked(Description) }}
          />
          {Links && (
            <div
              className='p-4 my-8 space-y-3 bg-blue-100 border-l-8 border-blue-600 rounded modal-dive-deeper'
              dangerouslySetInnerHTML={{ __html: marked(Links) }}
            />
          )}
        </div>
      </div>
    </div>
  );
}
