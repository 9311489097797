import React, { useContext } from 'react';
import Layout from 'components/layout';
import Sidebar from 'components/sidebar';
import SidebarItemHeader from 'components/sidebar-item-header';
import SidebarItem from 'components/sidebar-item';
import AuthContext from 'context/auth-context';
import { postMap, slugify } from 'utils/post-map';
import SEO from 'components/seo';

type Props = {
  children: React.ReactNode;
};

const PostsLayout = ({ children }: Props) => {
  const { user, isLoading } = useContext(AuthContext);

  if (isLoading || !user) return null;

  return (
    user && (
      <Layout>
        <SEO title='Emails' />
        <div className='grid grid-cols-8'>
          <Sidebar classes={['col-span-2', 'max-w-md']}>
            <SidebarItem slug={slugify(postMap.start_here.slug)}>
              <span className='text-xl font-bold'>
                {postMap.start_here.label}
              </span>
            </SidebarItem>
            <SidebarItemHeader>The Fund</SidebarItemHeader>

            <SidebarItem slug={slugify(postMap.project_kickoff.slug)}>
              {postMap.project_kickoff.label}
            </SidebarItem>

            <SidebarItem slug={slugify(postMap.making_the_sales_map.slug)}>
              {postMap.making_the_sales_map.label}
            </SidebarItem>

            <SidebarItem slug={slugify(postMap.nailing_our_pitch.slug)}>
              {postMap.nailing_our_pitch.label}
            </SidebarItem>

            <SidebarItem slug={slugify(postMap.the_taste_of_rejection.slug)}>
              {postMap.the_taste_of_rejection.label}
            </SidebarItem>

            <SidebarItem slug={slugify(postMap.going_down_the_funnel.slug)}>
              {postMap.going_down_the_funnel.label}
            </SidebarItem>

            <SidebarItem
              slug={slugify(postMap.how_1_tweet_turned_into_1_5_m.slug)}
            >
              {postMap.how_1_tweet_turned_into_1_5_m.label}
            </SidebarItem>

            <SidebarItem
              slug={slugify(postMap.sometimes_you_need_to_be_a_worker_ant.slug)}
            >
              {postMap.sometimes_you_need_to_be_a_worker_ant.label}
            </SidebarItem>

            <SidebarItem
              slug={slugify(postMap.good_artists_copy_great_artists_steal.slug)}
            >
              {postMap.good_artists_copy_great_artists_steal.label}
            </SidebarItem>

            <SidebarItem
              slug={slugify(
                postMap.hiring_a_good_operator_feels_like_magic.slug
              )}
            >
              {postMap.hiring_a_good_operator_feels_like_magic.label}
            </SidebarItem>

            <SidebarItem
              slug={slugify(
                postMap.behind_the_scenes_of_the_first_fund_lp_update.slug
              )}
            >
              {postMap.behind_the_scenes_of_the_first_fund_lp_update.label}
            </SidebarItem>

            <SidebarItemHeader>Ecommerce</SidebarItemHeader>

            <SidebarItem
              slug={slugify(
                postMap.what_do_vacuums_gummies_chalkboards_all_have_in_common
                  .slug
              )}
            >
              {
                postMap.what_do_vacuums_gummies_chalkboards_all_have_in_common
                  .label
              }
            </SidebarItem>

            <SidebarItem
              slug={slugify(postMap.impulse_vs_repeat_vs_splurge_products.slug)}
            >
              {postMap.impulse_vs_repeat_vs_splurge_products.label}
            </SidebarItem>

            <SidebarItem slug={slugify(postMap.the_power_box.slug)}>
              {postMap.the_power_box.label}
            </SidebarItem>

            <SidebarItem
              slug={slugify(postMap.validating_demand_for_product_ideas.slug)}
            >
              {postMap.validating_demand_for_product_ideas.label}
            </SidebarItem>

            <SidebarItem
              slug={slugify(
                postMap.tools_tips_tricks_to_finding_a_manufacturer.slug
              )}
            >
              {postMap.tools_tips_tricks_to_finding_a_manufacturer.label}
            </SidebarItem>

            <SidebarItem
              slug={slugify(postMap.product_samples_are_on_the_way.slug)}
            >
              {postMap.product_samples_are_on_the_way.label}
            </SidebarItem>

            <SidebarItem
              slug={slugify(postMap.growth_is_math_art_science.slug)}
            >
              {postMap.growth_is_math_art_science.label}
            </SidebarItem>

            <SidebarItem
              slug={slugify(postMap.the_art_of_non_violent_communication.slug)}
            >
              {postMap.the_art_of_non_violent_communication.label}
            </SidebarItem>

            <SidebarItem
              slug={slugify(postMap.firing_lots_of_growth_bullets.slug)}
            >
              {postMap.firing_lots_of_growth_bullets.label}
            </SidebarItem>

            <SidebarItem
              slug={slugify(postMap.finding_the_bug_in_the_system.slug)}
            >
              {postMap.finding_the_bug_in_the_system.label}
            </SidebarItem>

            <SidebarItem
              slug={slugify(
                postMap.the_more_i_know_about_facebook_ads_the_better.slug
              )}
            >
              {postMap.the_more_i_know_about_facebook_ads_the_better.label}
            </SidebarItem>

            <SidebarItem
              slug={slugify(
                postMap.i_hate_flying_blind_so_i_made_a_roas_calculator.slug
              )}
            >
              {postMap.i_hate_flying_blind_so_i_made_a_roas_calculator.label}
            </SidebarItem>

            <SidebarItem slug={slugify(postMap.we_got_our_sample.slug)}>
              {postMap.we_got_our_sample.label}
            </SidebarItem>

            <SidebarItem
              slug={slugify(
                postMap.tofu_isnt_just_something_i_like_to_eat.slug
              )}
            >
              {postMap.tofu_isnt_just_something_i_like_to_eat.label}
            </SidebarItem>

            <SidebarItem
              slug={slugify(
                postMap.three_ecom_tactics_that_drove_30k_in_incremental_revenue
                  .slug
              )}
            >
              {
                postMap.three_ecom_tactics_that_drove_30k_in_incremental_revenue
                  .label
              }
            </SidebarItem>

            <SidebarItemHeader>Growing an audience</SidebarItemHeader>

            <SidebarItem
              slug={slugify(
                postMap.using_big_mario_to_grow_the_aap_audience.slug
              )}
            >
              {postMap.using_big_mario_to_grow_the_aap_audience.label}
            </SidebarItem>

            <SidebarItem
              slug={slugify(
                postMap.spoiler_alert_targeting_tai_lopez_s_audience_is_working
                  .slug
              )}
            >
              {
                postMap.spoiler_alert_targeting_tai_lopez_s_audience_is_working
                  .label
              }
            </SidebarItem>

            <SidebarItem
              slug={slugify(postMap.prototyping_the_third_door.slug)}
            >
              {postMap.prototyping_the_third_door.label}
            </SidebarItem>

            <SidebarItem
              slug={slugify(
                postMap.the_internet_doesnt_care_about_geography.slug
              )}
            >
              {postMap.the_internet_doesnt_care_about_geography.label}
            </SidebarItem>

            <SidebarItem
              slug={slugify(postMap.picking_the_mission_for_the_week.slug)}
            >
              {postMap.picking_the_mission_for_the_week.label}
            </SidebarItem>

            <SidebarItem
              slug={slugify(postMap.were_not_selling_saddles_here.slug)}
            >
              {postMap.were_not_selling_saddles_here.label}
            </SidebarItem>

            <SidebarItem
              slug={slugify(postMap.debating_isn_t_just_for_presidents.slug)}
            >
              {postMap.debating_isn_t_just_for_presidents.label}
            </SidebarItem>

            <SidebarItem
              slug={slugify(
                postMap.painting_a_picture_while_tickling_the_brain.slug
              )}
            >
              {postMap.painting_a_picture_while_tickling_the_brain.label}
            </SidebarItem>

            <SidebarItemHeader>Wisdom</SidebarItemHeader>

            <SidebarItem
              slug={slugify(postMap.bankshots_only_work_in_basketball.slug)}
            >
              {postMap.bankshots_only_work_in_basketball.label}
            </SidebarItem>

            <SidebarItem
              slug={slugify(postMap.its_all_about_taking_the_right_risks.slug)}
            >
              {postMap.its_all_about_taking_the_right_risks.label}
            </SidebarItem>

            <SidebarItem
              slug={slugify(
                postMap.hitting_a_plateau_should_be_like_greeting_an_old_friend
                  .slug
              )}
            >
              {
                postMap.hitting_a_plateau_should_be_like_greeting_an_old_friend
                  .label
              }
            </SidebarItem>
            <SidebarItem
              slug={slugify(postMap.just_the_best_stuff_so_far.slug)}
            >
              {postMap.just_the_best_stuff_so_far.label}
            </SidebarItem>

            <SidebarItem slug={slugify(postMap.building_the_flywheel.slug)}>
              {postMap.building_the_flywheel.label}
            </SidebarItem>

            <SidebarItem
              slug={slugify(postMap.im_obsessed_with_this_business_model.slug)}
            >
              {postMap.im_obsessed_with_this_business_model.label}
            </SidebarItem>

            <SidebarItem slug={slugify(postMap.my_life_vision.slug)}>
              {postMap.my_life_vision.label}
            </SidebarItem>

            <SidebarItemHeader>Random</SidebarItemHeader>

            <SidebarItem
              slug={slugify(
                postMap.amas_audio_experiments_and_the_sunday_mailbag.slug
              )}
            >
              {postMap.amas_audio_experiments_and_the_sunday_mailbag.label}
            </SidebarItem>

            <SidebarItem slug={slugify(postMap.every_day_is_my_friday.slug)}>
              {postMap.every_day_is_my_friday.label}
            </SidebarItem>

            <SidebarItem
              slug={slugify(postMap.back_again_with_the_sunday_mailbag.slug)}
            >
              {postMap.back_again_with_the_sunday_mailbag.label}
            </SidebarItem>

            <SidebarItem slug={slugify(postMap.recap.slug)}>
              {postMap.recap.label}
            </SidebarItem>

            <SidebarItem
              slug={slugify(postMap.fridays_ama_25_day_recap_on_the_way.slug)}
            >
              {postMap.fridays_ama_25_day_recap_on_the_way.label}
            </SidebarItem>

            <SidebarItem
              slug={slugify(
                postMap.its_monday_and_i_wanna_help_you_nail_your_story.slug
              )}
            >
              {postMap.its_monday_and_i_wanna_help_you_nail_your_story.label}
            </SidebarItem>

            <SidebarItem
              slug={slugify(postMap.today_was_a_total_curveball.slug)}
            >
              {postMap.today_was_a_total_curveball.label}
            </SidebarItem>

            <SidebarItem
              slug={slugify(
                postMap.twenty_four_hours_of_learning_about_collectibles.slug
              )}
            >
              {postMap.twenty_four_hours_of_learning_about_collectibles.label}
            </SidebarItem>

            <SidebarItem slug={slugify(postMap.todays_ama_recording.slug)}>
              {postMap.todays_ama_recording.label}
            </SidebarItem>

            <SidebarItem
              slug={slugify(postMap.how_id_make_100k_in_a_week_right_now.slug)}
            >
              {postMap.how_id_make_100k_in_a_week_right_now.label}
            </SidebarItem>

            <SidebarItem
              slug={slugify(
                postMap.the_sunday_mailbag_a_special_guest_coming_this_week.slug
              )}
            >
              {
                postMap.the_sunday_mailbag_a_special_guest_coming_this_week
                  .label
              }
            </SidebarItem>

            <SidebarItem slug={slugify(postMap.ripping_the_bandaid_off.slug)}>
              {postMap.ripping_the_bandaid_off.label}
            </SidebarItem>

            <SidebarItem
              slug={slugify(
                postMap.my_5_lessons_learned_from_jack_butcher.slug
              )}
            >
              {postMap.my_5_lessons_learned_from_jack_butcher.label}
            </SidebarItem>
          </Sidebar>
          <div className='container relative max-w-3xl p-4 pb-12 mx-auto mt-10 antialiased leading-relaxed break-words md:px-16 md:py-10 col-span-full md:col-span-6 md:mt-0'>
            {children}
          </div>
        </div>
      </Layout>
    )
  );
};

export default PostsLayout;
