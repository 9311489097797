import clsx from 'clsx';
import React, { useState } from 'react';

type Props = {
  children: React.ReactNode;
  classes: string[];
};

export default function Sidebar({ children, classes }: Props) {
  const [isActive, setIsActive] = useState(false);

  const toggleMenu = () => {
    setIsActive(!isActive);
  };
  return (
    <>
        <button
          className={`fixed z-20 right-0 p-2 text-right m-2 cursor-pointer border border-gray-300 rounded md:hidden bg-blue-50 opacity-90`}
          onClick={toggleMenu}
        >
          {isActive ? 'Close' : 'Menu'}
        </button>
      <div
        className={`${clsx(
          isActive ? 'fixed' : 'hidden'
        )} h-full md:hidden w-full max-w-full z-10 border-r pb-8 bg-blue-50 col-span-full border-gray-300 min-h-full overflow-auto ${clsx(
          classes
        )}`}
      >
        {children}
      </div>
      <div
        className={`border-r bg-blue-50 border-gray-300 hidden md:block min-h-full overflow-auto relative ${clsx(
          classes
        )}`}
      >
        {children}
      </div>
    </>
  );
}
