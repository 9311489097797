import clsx from 'clsx';
import React, { useState } from 'react';
import { Eye, EyeOff } from 'react-feather';

type Props = {
  label: string;
  text: string;
};

export default function Hidden({ label, text }: Props) {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div
      className={`cursor-pointer w-full md:w-1/3 m-auto mb-6 flex items-center px-4 py-2 transition-colors duration-100 text-white ${
        clsx(isVisible ? 'bg-indigo-200' : 'bg-indigo-600')
      } rounded-lg shadow`}
      onClick={() => setIsVisible(!isVisible)}
    >
      <span className='flex-shrink'>{isVisible ? <EyeOff /> : <Eye />}</span>
      <span className={ `flex-grow text-center transition duration-200 ${clsx(isVisible && ['text-red-500 s', 'font-mono', 'font-bold', 'tracking-wide'])}` }>{isVisible ? text : label}</span>
    </div>
  );
}
