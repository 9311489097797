import React, { ChangeEvent, useContext, useEffect } from 'react';
import ToolboxContext from 'context/toolbox-context';
import Layout from 'components/layout';
import Sidebar from 'components/sidebar';
import SidebarItemHeader from 'components/sidebar-item-header';
import useToolboxFilter, {
  CategoryType,
  MediaType,
} from 'hooks/use-toolbox-filter';
import { navigate } from 'gatsby';
import AuthContext from 'context/auth-context';
import SEO from 'components/seo';

type Props = {
  children: React.ReactNode;
};

const ToolboxLayout = ({ children }: Props) => {
  const { user, isLoading } = useContext(AuthContext);

  useEffect(() => {
    !user && navigate('/login');
  });

  const value = useToolboxFilter();
  const { dispatchFilter, filterState } = value;

  const handleFilterChange = (
    type: 'category' | 'media',
    payload: CategoryType | MediaType,
    isChecked: boolean
  ) => {
    if (type === 'category') {
      if (isChecked) {
        dispatchFilter({
          type: 'add_category_filter',
          payload: payload as CategoryType,
        });
      } else {
        dispatchFilter({
          type: 'remove_category_filter',
          payload: payload as CategoryType,
        });
      }
    } else if (type === 'media') {
      if (isChecked) {
        dispatchFilter({
          type: 'add_media_filter',
          payload: payload as MediaType,
        });
      } else {
        dispatchFilter({
          type: 'remove_media_filter',
          payload: payload as MediaType,
        });
      }
    }
  };

  if (isLoading || !user) return null;

  return (
    user && (
      <ToolboxContext.Provider value={value}>
        <Layout>
          <SEO title='Big Ideas' />
          <div className='grid grid-cols-8 bg-gradient-to-tl from-orange-50 to-blue-50'>
            <Sidebar classes={['col-span-2', 'max-w-md', 'space-y-4']}>
              <SidebarItemHeader>Narrow Selection</SidebarItemHeader>
              <div className='space-y-3'>
                <span className='block px-4 text-xl font-bold'>Category</span>
                <label
                  htmlFor='framework'
                  className='block px-4 text-xl md:text-sm'
                >
                  <input
                    className='mr-1'
                    type='checkbox'
                    name='framework'
                    id='framework'
                    checked={filterState.categoryFilters.has('framework')}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleFilterChange(
                        'category',
                        'framework',
                        e.target.checked
                      )
                    }
                  />
                  Framework
                </label>
                <label
                  htmlFor='template'
                  className='block px-4 text-xl md:text-sm'
                >
                  <input
                    className='mr-1'
                    type='checkbox'
                    name='template'
                    id='template'
                    checked={filterState.categoryFilters.has('template')}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleFilterChange(
                        'category',
                        'template',
                        e.target.checked
                      )
                    }
                  />
                  Template
                </label>
                <label
                  htmlFor='philosophy'
                  className='block px-4 text-xl md:text-sm'
                >
                  <input
                    className='mr-1'
                    type='checkbox'
                    name='philosophy'
                    id='philosophy'
                    checked={filterState.categoryFilters.has('philosophy')}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleFilterChange(
                        'category',
                        'philosophy',
                        e.target.checked
                      )
                    }
                  />
                  Philosophy
                </label>
                <label
                  htmlFor='technique'
                  className='block px-4 text-xl md:text-sm'
                >
                  <input
                    className='mr-1'
                    type='checkbox'
                    name='technique'
                    id='technique'
                    checked={filterState.categoryFilters.has('technique')}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleFilterChange(
                        'category',
                        'technique',
                        e.target.checked
                      )
                    }
                  />
                  Technique
                </label>
              </div>
              <div className='space-y-3'>
                <span className='block px-4 text-xl font-bold'>Media</span>
                <label htmlFor='text' className='block px-4 text-xl md:text-sm'>
                  <input
                    className='mr-1'
                    type='checkbox'
                    name='text'
                    id='text'
                    checked={filterState.mediaFilters.has('text')}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleFilterChange('media', 'text', e.target.checked)
                    }
                  />
                  Text
                </label>
                <label htmlFor='text' className='block px-4 text-xl md:text-sm'>
                  <input
                    className='mr-1'
                    type='checkbox'
                    name='video'
                    id='video'
                    checked={filterState.mediaFilters.has('video')}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleFilterChange('media', 'video', e.target.checked)
                    }
                  />
                  Video
                </label>
              </div>
            </Sidebar>
            <div className='container relative w-full min-h-screen p-4 pb-12 md:col-span-6 col-span-full md:px-16 md:py-10'>
              {user && children}
            </div>
          </div>
        </Layout>
      </ToolboxContext.Provider>
    )
  );
};

export default ToolboxLayout;
