import { Magic } from 'magic-sdk';
import { createContext, Dispatch, SetStateAction } from 'react';

type ContextState = {
  user: Email | null;
  setUser: Dispatch<SetStateAction<Email | null>>;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  magic: Magic;
};

const AuthContext = createContext({} as ContextState);

export default AuthContext;
