import { Link } from '@reach/router';
import React from 'react';

type ButtonProps = {
  text: string;
  slug: string;
};

type Props = {
  prevButton: ButtonProps;
  nextButton: ButtonProps;
};

export default function PostFooter({ prevButton, nextButton }: Props) {
  return (
    <div className='flex'>
      <div className='flex flex-col justify-center w-full space-y-4 md:space-y-0 md:space-x-8 md:flex-row'>
        {prevButton && (
          <Link to={prevButton.slug}>
            <button className='w-full px-6 py-4 text-sm font-bold text-orange-800 transition-colors duration-200 ease-in bg-orange-200 rounded hover:bg-orange-100'>
              ← {prevButton.text}
            </button>
          </Link>
        )}
        {nextButton && (
          <Link to={nextButton.slug}>
            <button className='w-full px-6 py-4 text-sm font-bold text-orange-800 transition-colors duration-200 ease-in bg-orange-200 rounded hover:bg-orange-100'>
              {nextButton.text} →
            </button>
          </Link>
        )}
      </div>
    </div>
  );
}
