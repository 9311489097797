module.exports = [{
      plugin: require('../node_modules/@raae/gatsby-remark-oembed/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Pragati Narrow","Poppins"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"commonmark":true,"defaultLayouts":{"default":"/opt/build/repo/src/components/layout.tsx"},"gatsbyRemarkPlugins":[{"resolve":"@raae/gatsby-remark-oembed","options":{"usePrefix":["oembed","video"]}},{"resolve":"gatsby-remark-images","options":{"maxWidth":1600,"linkImagesToOriginal":false,"quality":100}}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"all-access-pass","short_name":"aap","start_url":"/","background_color":"#fb923c","theme_color":"#fb923c","display":"minimal-ui","icon":"src/images/aap-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3625358cf9c2300e0214af9d4850f8c9"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
