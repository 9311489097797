import { useReducer, useState } from 'react';
 
export type CategoryType = 'framework' | 'philosophy' | 'template' | 'technique';

export type MediaType = 'text' | 'Text' | 'video' | 'Video' | 'audio' | 'Audio';

export type ToolboxFilterState = Readonly<{
  categoryFilters: Set<CategoryType> | Set<unknown>;
  mediaFilters: Set<MediaType> | Set<unknown>;
}>;

export type ToolboxFilterActions =
  | {
      type: 'add_category_filter';
      payload: CategoryType;
    }
  | {
      type: 'add_media_filter';
      payload: MediaType;
  } | {
    type: 'remove_category_filter',
    payload: CategoryType
    } | {
    type: 'remove_media_filter',
    payload: MediaType
    };

const initialState = {
  categoryFilters: new Set(),
  mediaFilters: new Set(),
};

export type ToolboxData = {
  Name: string;
  Description: string;
  Tags: CategoryType[];
  Media_Type: MediaType;
  Links: string;
  Hide_Intro: boolean;
  Cover_Image: {
    filename: string;
    thumbnails: {
      small: {
        height: number;
        width: number;
        url: URLString;
      };
      large: {
        height: number;
        width: number;
        url: URLString;
      };
    };
  }[];
};

function reducer(state: ToolboxFilterState, action: ToolboxFilterActions) {
  const { type, payload } = action;
  switch (type) {
    case 'add_category_filter': {
      const categoryFilters = new Set(state.categoryFilters);
      categoryFilters.add(payload as CategoryType);
      return { ...state, categoryFilters };
    }

    case 'add_media_filter': {
      const mediaFilters = new Set(state.mediaFilters);
      mediaFilters.add(payload as MediaType);
      return { ...state, mediaFilters };
    }

    case 'remove_category_filter': {
      const categoryFilters = new Set(state.categoryFilters);
      categoryFilters.delete(payload as CategoryType);
      return { ...state, categoryFilters };
    }

    case 'remove_media_filter': {
      const mediaFilters = new Set(state.mediaFilters);
      mediaFilters.delete(payload as MediaType);
      return { ...state, mediaFilters };
    }

    default:
      throw new Error();
  }
}

export default function useToolboxFilter() {
  const [filterState, dispatchFilter] = useReducer(reducer, initialState);
  const [
    selectedToolState,
    setSelectedToolState,
  ] = useState<ToolboxData | null>(null);

  return {
    filterState,
    dispatchFilter,
    selectedToolState,
    setSelectedToolState,
  };
}
