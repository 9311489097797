import { Magic } from 'magic-sdk';
import { useEffect, useState } from 'react';

export default function useAuth() {
  const [magic, setMagic] = useState<Magic | undefined>();
  const [user, setUser] = useState<Email | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const magic = new Magic(
        process.env.GATSBY_MAGIC_PUBLISHABLE_KEY as string
      );
      setMagic(magic);

      try {
        const res = await fetch('/.netlify/functions/user');
        const { user, authorized } = await res.json();

        const loggedIn = authorized ? user : false;

        !loggedIn && magic && (await magic.user.logout());

        if (loggedIn) {
          authorized && setUser(user);
          setIsLoading(false);
        }
      } catch (error) {
        throw error;
      }
    })();
  }, []);

  return { user, setUser, isLoading, setIsLoading, magic };
}
